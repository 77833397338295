<template>
  <div class="flex flex-col gap-y-6 lg:gap-y-0">
    <div class="flex lg:hidden flex-row bg-white py-3">
      <svg
        @click="openMobileNav"
        class="cursor-pointer"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#111"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 18l-6-6 6-6"
        />
      </svg>
      <div class="flex-1 flex justify-center font-EffraM text-center text-lg">
        Carte nationale
      </div>
    </div>

    <cinVerify
      token="x"
      class="px-10"
      custom-class="bg-white lg:bg-transparent"
    ></cinVerify>
  </div>
</template>

<script>
const cinVerify = () => import("@/views/global-components/cinVerify.vue");
import { EventBus } from "@/event-bus";

export default {
  components: { cinVerify },
  methods: {
    openMobileNav() {
      EventBus.$emit("Change_Page_Number_My_Account_Patient", -1);
    }
  }
};
</script>

<style>
</style>